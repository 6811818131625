import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import _ from "lodash";
import { trackTiming } from "../../../utils/mrTracker";
import { useHistory } from "react-router-dom";
import { Button, MenuItem, MenuPopover } from "@netapp/bxp-design-system-react";
import { isNewTenancyEnabled } from "utils/localstorage";
import { isAllowed } from "utils/permissions";


const NotificationsContextMenu = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const isDismissingAll = useSelector(state => state.uiNotifications.isDismissingAll);
    const count = useSelector(state => state.uiNotifications.notifications.length);
    const notifications = useSelector(state => state.uiNotifications.notifications);
    const isTenancyV4 = isNewTenancyEnabled();
    const { permissions, selectedScope } = useSelector(state => state.tenancy);

    const hasPermissions = (permissionKey) => {
        if (!isTenancyV4 && !permissions.allTabsAvailable) {
            return false
        }
        if (isTenancyV4 && (permissionKey && !(isAllowed(selectedScope?.rules, permissionKey, "access")
                            || isAllowed(selectedScope?.rules, permissionKey, "read")))) {
            return false
        }
        return true
    }


    const forwardToSettings = () => {

        history.push('/alerts-and-notifications/users')
        dispatch({
            type: "MENU:CLOSE-WIDGET"
        })
    }

    useEffect(() => {
        const start = performance.now();
        dispatch({
            type: "TRACKING", payload: {
                category: "UiNotifications", action: "FlowAction:OpenMenu"
            }
        })
        return () => {
            const end = performance.now();
            dispatch({
                type: "TRACKING", payload: {
                    category: "UiNotifications", action: "FlowAction:CloseMenu"
                }
            })
            trackTiming({category: "UiNotifications", name: "widget open", value: end - start})
        }
    }, [dispatch]);

    return <>
        { hasPermissions("notifications") && <Button type='text' style={{ position: 'absolute', right: '90px' }} onClick={forwardToSettings}>Go To Settings</Button>}
        { hasPermissions("notifications") && <MenuPopover Trigger="small">
            <MenuItem disabled={isDismissingAll || count === 0} onClick={(e) => {
                dispatch({
                    type: "UI-NOTIFICATIONS:DISMISS-ALL", payload: {
                        ids: _.map(notifications, 'id')
                    }, track: true
                })

                e.stopPropagation();
            }}>Dismiss All</MenuItem>
        </MenuPopover>}
    </>
}

export default NotificationsContextMenu;
