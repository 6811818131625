import _ from "lodash";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { 
    Button,
    DropdownButton,
    TransitionChevron,
    FormFieldSelect,
    Text,
    MenuPopover,
    Span
     } from "@netapp/bxp-design-system-react";
import { apiClient } from "../../utils/clients";
import { DeleteIcon } from "@netapp/bxp-style/react-icons/Action";
import styles from "./components/Settings.module.scss";
import { ProjectIcon  } from "@netapp/bxp-design-system-react/icons/monochrome";
import { CompanyIcon, FolderIcon } from "@netapp/bxp-style/react-icons/General"
import { UserIcon } from "@netapp/bxp-style/react-icons/Action";
import { ServiceAccountIcon  } from "@netapp/bxp-design-system-react/icons/monochrome";
import externals from "utils/externals";

export const emailRegex = /^(?=.{0,255}$)[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.([a-zA-Z]{2,20})$/;
export const serviceAccountNameRegex = /^[-_.a-zA-Z0-9]{1,40}$/;
export const GRID_PAGESIZE = 50;
export const USERS_GRID_THRESHOLD = 300;
export const org = "organization";
export const project = "project";

export const SCOPE_ROLE_MAP = {
    "project": "internal:bxp:isProjectRole",
    "organization": "internal:bxp:isOrganizationRole",
    "folder": "internal:bxp:isFolderRole"
}


export const FEDERATIONSTATEMAP = {
    "DRAFT": { label: "Draft", iconType: "info" },
    "CREATED": { label: "Created", iconType: "info" },
    "TESTED": { label: "Tested", iconType: "info" },
    "ENABLED": { label: "Enabled", iconType: "success" },
    "DISABLED": { label: "Disabled", iconType: "info" },
    "FAILED": { label: "Failed", iconType: "info" }
}
export const FEDERATIONPROVIDERMAP = {
    "ENTRAID": "Microsoft Entra ID",
    "PING": "Ping federation",
    "ADFS": "ADFS",
    "SAML": "SAML"
}

export const getPaginationQuery = (
    pageSize,
    sortState,
    filterState,
    pageIndex,
    defaultFilters = null
) => {
    let query = {};
    if(pageSize) {
        query = {
            limit: pageSize,
            skip: pageSize * pageIndex,
        };
    }
    if (sortState && sortState.column && sortState.sortOrder) {
        query.order_by = `${sortState.column}${sortState.sortOrder === ' desc' ? sortState.sortOrder : ''}`;
    }
    if (defaultFilters) {
        query.filter = defaultFilters;
    }
    return query;
};

export const isOrganizationAdmin = (role) => {
    return !_.isUndefined(role?.rules) && role.rules.includes("bxp:organization-create")
           && role.rules.includes("bxp:organization-modify")
           && role.rules.includes("bxp:organization-delete")
}

export const isAdmin = (role) => {
    return !_.isUndefined(role?.rules) && role.rules.includes("bxp:organization-create")
           && role.rules.includes("bxp:organization-modify")
           && role.rules.includes("bxp:organization-delete")
           && role.rules.includes("bxp:folder-create")
           && role.rules.includes("bxp:folder-modify")
           && role.rules.includes("bxp:folder-delete")
           && role.rules.includes("bxp:project-create")
           && role.rules.includes("bxp:project-modify")
           && role.rules.includes("bxp:project-delete")
}

export const isScopeAdmin = (role) => {
    return !_.isUndefined(role?.rules) && !isOrganizationAdmin(role)
    && role.rules.includes("bxp:folder-create")
    && role.rules.includes("bxp:folder-modify")
    && role.rules.includes("bxp:folder-delete")
    && role.rules.includes("bxp:project-create")
    && role.rules.includes("bxp:project-modify")
    && role.rules.includes("bxp:project-delete")
}

export const isPlatformViewer = (role) => {
    return getTagValue(role.tags, "internal:bxp:legacyId") === "Role-3"
}

export const isSnapcenterAdmin = (role) => {
    return getTagValue(role.tags, "internal:bxp:legacyId") === "Role-4"
}

export const isSnapcenterSystem = (role) => {
    return getTagValue(role.tags, "internal:bxp:legacyId") === "Role-5"
}

export function useAPIQuery(urlObject) {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(urlObject?.url ? true: false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!_.isEmpty(urlObject?.url)) {
            (async () => {
                setIsLoading(true);
                setError(null);
                try {
                    let response;
                    if(urlObject.query) {
                        response = await apiClient.get(urlObject.url, { params: urlObject?.query });
                    } else {
                        response = await apiClient.get(urlObject.url);
                    }
                    const data = JSON.parse(response.data);
                    setData(data);
                }
                catch (err) {
                    setError(err);
                } finally {
                    setIsLoading(false);
                }
            })();
        }
    }, [urlObject]);
    return { data, isLoading, error }
}

export const getFoldersAndProjectsMap = (resources) => {
    const foldersAndProjectsMap = {
        folders: [],
        projects: []
    };
    _.forEach(resources, function (resource) {

        if (!_.isEmpty(resource["childScopes"])) {
            const childScopesFoldersAndProjectsMap = getFoldersAndProjectsMap(resource["childScopes"]);
            foldersAndProjectsMap.folders = [...foldersAndProjectsMap.folders, ...childScopesFoldersAndProjectsMap.folders];
            foldersAndProjectsMap.projects = [...foldersAndProjectsMap.projects, ...childScopesFoldersAndProjectsMap.projects];
        }
        if (resource.resourceType === "folder") {
            foldersAndProjectsMap.folders.push(resource);
        }

        if (resource.resourceType === "project") {
            foldersAndProjectsMap.projects.push(resource);
        }
    });
    return foldersAndProjectsMap;
}

export const isScopeRoleValid = (scope, role) => {
    if (scope?.resourceType === org && isScopeAdmin(role)) {
        return false;
    }
    if (scope?.resourceType !== org && isOrganizationAdmin(role)) {
        return false;
    }
    return true;
}

/* This function is used to find the scopes for a resource.
 * @param {Object} selectedOrg - selectedOrg data
 * @param {String} scopeId - Id of the resource
 * output - Array of scopes for the resource. Ex: ["gil", "ws1"]  Ex2:  ["gil", "ws2", "test"] }]
 */
export const findPathToScope = (selectedOrg, scopeId, arr = []) => {
    if (!selectedOrg) {
        return [];
    }
    const path = [...arr, { id: selectedOrg.id, name: selectedOrg.name, rules: selectedOrg.rules }];
    if (selectedOrg.id === scopeId) {
        return path;
    }
    for (let child of selectedOrg.childScopes) {
        const result = findPathToScope(child, scopeId, path);
        if (result.length) {
            return result;
        }
    }
    return [];
};

// Get existing accessible scope IDs
export const getScopeIds = (selectedUser, allRoles, orgId) => {
    const orgAdminRoleId = allRoles.find(r => isOrganizationAdmin(r)).id
    if (selectedUser?.roles?.find(id => id === orgAdminRoleId)) {
        const scopeIdMap = {};
        scopeIdMap[orgId] = true;
        return scopeIdMap;
    } else {
        // This list is prepared to disable access to already accessible scopes, 
        // hence platform viewer's org access is skipped as its just a read access
        return selectedUser?.roles?.length > 0 && _.reduce(selectedUser.tags,
            (acc, tag) => {
                const key = Object.keys(tag)[0];
                if (key === "internal:bxp:projectId" || key === "internal:bxp:folderId") {
                    acc[tag[key]] = true
                }
                return acc;
            },
            {}
        );
    }
}

export const countResourceTypes = (userScopes, organization) => {
    if (!userScopes || userScopes.length === 0) {
        return { folderCount: 0, projectCount: 0 };
    } else {
        const userScopesSorted = _.sortBy(userScopes, (scope) => scope.length)
        const scopeWithAccess = {};
        for (const userScope of userScopesSorted) {
            if (Object.keys(scopeWithAccess).length === 0) {
                scopeWithAccess[userScope[`${userScope.type}Id`]] = userScope[`${userScope.type}Id`]
            } else {
                let hasParentAccess = false
                for (const scope of userScope.scopeDetails) {
                    if (scopeWithAccess[scope.id]) {
                        hasParentAccess = true
                        break
                    }
                }
                if (!hasParentAccess) {
                    scopeWithAccess[userScope[`${userScope.type}Id`]] = userScope[`${userScope.type}Id`]
                }
            }
        }
        // Get Resource Count
        const count = { folderCount: 0, projectCount: 0 }
        const scopes = [organization]
        while (scopes.length > 0) {
            const scope = scopes[0]
            if (scopeWithAccess[scope.id]) {
                if (scope.resourceType === 'folder') {
                    count.folderCount += 1
                }
                if (scope.resourceType === 'project') {
                    count.projectCount += 1
                }
            }
            if (scope.childScopes?.length > 0) {
                for (const childScope of scope.childScopes) {
                    if (scopeWithAccess[scope.id]) {
                        scopeWithAccess[childScope.id] = childScope.id
                    }
                    scopes.push(childScope)
                }
            }
            scopes.shift()
        }
        return count
    }
}

export const scopeTypeIconRenderer = (element) => {
    if (element.resourceType === "organization") {
        return <CompanyIcon className={element?.isSelected ? "" : styles["dark-icon"]} width="20"/>
    } else if (element.resourceType === "folder") {
        return <FolderIcon className={element?.isSelected ? "" : styles["dark-icon"]} width="20"/>
    } else if (element.resourceType === "project") {
        return <ProjectIcon className={element?.isSelected ? "" : styles["dark-icon"]} width="20"/>
    }
    return null
}

export const orgColumnsHeaders = [
    {
        header: "Name",
        accessor: "name",
        id: "name",
        sort: {
            enabled: true,
        },
        Renderer: ({ row }) => {
            return row.resourceType === "organization" ? (
                <div className={styles["colum-cell-treegrid"]}>
                    <CompanyIcon width="20" className={styles["grid-icon"]}></CompanyIcon>
                    <Text
                        style={{
                            marginLeft: "10px",
                            verticalAlign: "super",
                            wordBreak: "break-word"
                        }}
                    >
                        {row.name}
                    </Text>
                </div>
            ) : row.resourceType === "folder" ? (
                <div className={styles["colum-cell-treegrid"]}>
                    <FolderIcon width="20" className={styles["grid-icon"]}></FolderIcon>
                    <Text
                        style={{
                            marginLeft: "10px",
                            verticalAlign: "super",
                            wordBreak: "break-word",
                        }}
                    >
                        {row.name}
                    </Text>
                </div>
            ) : (
                <div className={styles["colum-cell-treegrid"]}>
                    <ProjectIcon width="20" className={styles["grid-icon"]}></ProjectIcon>
                    <Text
                        style={{
                            marginLeft: "10px",
                            verticalAlign: "super",
                            wordBreak: "break-word"
                        }}
                    >
                        {row.name}
                    </Text>
                </div>
            );
        },
    },
];
export const RESOURCES_SELECTABLE_MAP = {
    Folder: {
        label: "Folder",
        description:
            "You can use folders to group related projects or create boundaries between them.",
        Icon: FolderIcon,
    },
    Project: {
        label: "Project",
        description:
            "You must create at least one project. Projects are the foundation of NetApp identity access and management.",
        Icon: ProjectIcon,
    }
};

 // Validation for ResourceName and Location
export const validator = (isOrganization) =>
  yup.object().shape({
    name: yup
      .string()
      .required("Field is required.")
      .matches(
        /^.{1,300}$/,
        "Please enter a name that contains 1 to 300 characters.",
      ),
    parent: !isOrganization
      ? yup.array().min(1).required("Field is required")
      : null,
  });

export const createFilterExpression = (externalfilter, optionString) => {
    if (!externalfilter) {
      externalfilter = `${optionString}`
    } else {
      externalfilter = `${externalfilter} or ${optionString}`
    }
    return externalfilter
}

export const getSelectedResources = (selectedRows) => {
    const selectedResources = [];
    for (const selectedRow of Object.keys(selectedRows)) {
        if (selectedRows[selectedRow]) {
            selectedResources.push(selectedRow);
        }
    }
    return selectedResources;
}

export const recursiveSearch = (recursiveObjectKey, parentData, searchString, isParentMatched = false) => {
    /**
     * recursiveObjectKey: is the key in the object that you want do the recursive search on.
     * parentData: is the main object that you pass to filter out the search.
     * searchString: is the text that you want to search.
     * !!! isParentMatched: No need to pass this parameter, it is being set and passed internally.
     */
    let matchedChildNodes = []
    _.forEach(parentData, childNode => {
        const childNodeMatched = _.toLower(childNode.name).includes( _.toLower(searchString));
        if(childNode.resourceType === "organization" && childNodeMatched) {
            matchedChildNodes = parentData; 
            return;
        }        
        let childNodesResults = [];
        if (childNode[recursiveObjectKey]?.length > 0) {
            childNodesResults = recursiveSearch(recursiveObjectKey, childNode[recursiveObjectKey], searchString, childNodeMatched);
        }
        if (childNodeMatched || childNodesResults.length > 0) {
            const newNode = { ...childNode };
            if (childNodesResults.length > 0) {
                newNode[recursiveObjectKey] = childNodesResults;
            }
            matchedChildNodes.push(newNode);
        }
    });
    return matchedChildNodes;
}

export const getTableColumns = ({dropdownUsers, onSelectUser, deleteRow, addMultiRoleHandler}) => {
    return [
        {
            header: "Type",
            accessor: "userType",
            id: "userType",
            width: 100,
            fixedWidth: true,
            sort: {
                enabled: false
            },
            filter: {
                filter: (value, row, filterState) => {
                    if (filterState.values.serviceAccount && (row.userType === 'serviceAccount' || row.userType === 'agent')) {
                        return true;
                    }
                    if (filterState.values.user && row.userType === 'user') {
                        return true;
                    }
                },
                enabled: true
            },
            Renderer: (user) => {
                const { row: { userType } } = user;
                return (
                    <div className={styles["grid-icon"]}>
                        {userType === "user" && <UserIcon />}
                        {userType ===  "serviceAccount" && <ServiceAccountIcon />}
                    </div>
                );
            }
        },
        {
            header: "Name",
            accessor: "name",
            id: "name",
            width: 300,
            sort: {
                enabled: false
            },
            filter: {},
            Renderer: ({ row }) => {
                return (
                    <>
                        {row.name ? (
                            row.name
                        ) : (
                            <FormFieldSelect
                                options={dropdownUsers}
                                isSearchable
                                value={dropdownUsers?.find((user) => user.name === row.name)}
                                className={styles["user-select-dropdown"]}
                                onChange={(e) =>
                                    onSelectUser(e, row.id, "name")
                                }
                                placeholder="Select a user"
                                menuPortalTarget={document.body}
                            />
                        )}
                    </>
                );
            },
        },
        {
            header: "Role",
            accessor: "role",
            id: "role",
            width:300,
            sort: {
                enabled: false
            },
            Renderer: ({ row }) => {
                const userRoles = row.userRoles;
                let lengthCount = 0;
                let maxIndex = 0;
                return (
                    <>
                    {!_.size(userRoles) ? (
                        <Button onClick={(e) => addMultiRoleHandler(e, row)} variant="text">
                            Assign a Role
                        </Button>
                    ) : userRoles.length === 1 ? (
                        userRoles[0].name
                    ) : (
                        <>
                            {userRoles.map((role, index) => {
                                if (lengthCount <= 25) {
                                    maxIndex = index + 1;
                                    lengthCount = lengthCount + role.name.length;
                                    return (
                                        <div key={index}>
                                            {" "}{role.name}{lengthCount <= 25 && ";"}{" "}
                                        </div>
                                    );
                                }
                            })}
                            {maxIndex !== userRoles.length && (
                                <Span color="info">+{userRoles.length - maxIndex}</Span>
                            )}
                        </>
                    )}
                </>
                
                );
            }
        },
        {
            header: "",
            accessor: "",
            width: 80,
            sort: {
                enabled: false,
            },
            filter: {
                enabled: false,
            },
            Renderer: ({ row, rowState }) => {
                return (
                    <div className={styles["delete-row-action-menu"]}>
                        <TransitionChevron isActive={rowState.isExpanded} color="grey" thin wide/>
                        { row.deletableColumn && <Button
                            variant="icon"
                            color="secondary"
                            style={{ width: 14, height: 14 }}
                            onClick={() => deleteRow(row)}>
                            <DeleteIcon />
                        </Button>}
                    </div>
                );
            },
        },
    ];
}

export const getUsersSummary = (users) => {
    let str = "";
    const roleMap = new Map();
    _.forEach(users, (user) => {
        _.forEach(user.userRoles, (role) => {
            if (!roleMap.has(role.name)) {
                roleMap.set(role.name, 1);
            } else {
                let count = roleMap.get(role.name);
                count++;
                roleMap.set(user.gridRoleName, count);
            }
        })
    })
    
    roleMap.forEach( (value, key) => {
        if(!str.length) {
            str = `${value} ${key} `
        } else {
            str = `${str} | ${value} ${key}`
        }
    });
    return str ? str : "Action needed"
}

// TODO this logic need to be changed for Multi - Role support.
export const getRoleWithHigherAccess = (roles) => {
    let selectedRole;
    _.forEach([isOrganizationAdmin, isScopeAdmin, isPlatformViewer, isSnapcenterAdmin, isSnapcenterSystem], (isRoleType) => {
        const selectedUserRole = roles.find(role => isRoleType(role));
        if(!_.isEmpty(selectedUserRole)) {
            selectedRole = selectedUserRole;
            return false;
        }
    });
    return _.isUndefined(selectedRole) ? roles[0] : selectedRole
}

export const getBoolean = (value) => {
    if (_.isUndefined(value) || (value !== "true" && value !== "false")) {
        return false;
    }
    return value === "true" ? true : false;
}

export const getTagValue = (tags, key) => {
    const tag =  _.find(tags, tag => !_.isUndefined(tag[key]))
    return tag ? tag[key] : undefined;
}

export const getRoleName = (allRoles, roleIds) => {
    const selectedUserRoles = _.map(roleIds, function (roleId) {
        const selectedUserRole = allRoles.find(userRole => userRole.id === roleId);
        return selectedUserRole;
    })
    return getRoleWithHigherAccess(selectedUserRoles);
};

export const getRolesByIds = (allRoles, roleIds, nonEditableRoles, isEdit) => {
    return _.map(roleIds, function (roleId) {
        const selectedUserRole = allRoles.find(userRole => userRole.id === roleId);
        const isModifyAllowed = !_.includes(nonEditableRoles, roleId) && isEdit;
        selectedUserRole.deletableColumn = isModifyAllowed
        selectedUserRole.editableColumn = isModifyAllowed
        return selectedUserRole;
    });
};

export const mergeArray = (array1, array2, property) => {
    const mergedData = {};
    for (const item of array1) {
        mergedData[item[property]] = item;
    }
    for (const item of array2) {
        mergedData[item[property]] = item;
    }
    return Object.values(mergedData);
}

export const getRoleId = (allRoles, roleName) => {
    const selectedRole = _.filter(allRoles, function (role) {
        return role.gridRoleName === roleName
    })
    return selectedRole;
};

export const hasAncestor = (ancestors, ancestorMap) => {
    for (const ancestor of ancestors) {
        if (ancestorMap[ancestor]) {
            return true
        }
    }
    return false
}

export const createAgentRoleAPIList = (rolesMapped) => {
    const apiList = [];
    for (const {scope, ancestors } of rolesMapped) {
        _.forEach(scope, eachScope => apiList.push(`${externals.tenancyV4Backend}/${eachScope.resourceType}s/${eachScope.id}/users`))
    }
    return apiList;
}

export const createRoleAPIList = (rolesMapped) => {
    if (rolesMapped.length === 1) {
        return [`${externals.tenancyV4Backend}/${rolesMapped[0].scope.resourceType}s/${rolesMapped[0].scope.id}/roles/${rolesMapped[0].role.value}/users`]
    }
    rolesMapped = rolesMapped.sort((from, to) => {
        const fromAncestors = from.scope.resourceType === "organization" ? [] : getTagValue(from.scope.tags, 'internal:bxp:ancestors').split(',')
        const toAncestors = to.scope.resourceType === "organization" ? [] : getTagValue(to.scope.tags, 'internal:bxp:ancestors').split(',')
        from['ancestors'] = fromAncestors;
        to['ancestors'] = toAncestors;
        return fromAncestors.length - toAncestors.length;
    });
    const parentScopeWithAdmin = {};
    const apiList = [];
    for (const { role, scope, ancestors } of rolesMapped) {
        if (isOrganizationAdmin(role)) {
            apiList.push(`${externals.tenancyV4Backend}/${scope.resourceType}s/${scope.id}/roles/${role.value}/users`);
            break;  
        } else if (!hasAncestor(ancestors, parentScopeWithAdmin)) {
            if (isScopeAdmin(role)) {
                parentScopeWithAdmin[scope.id] = scope;
            }
            apiList.push(`${externals.tenancyV4Backend}/${scope.resourceType}s/${scope.id}/roles/${role.value}/users`);
        }
    }
    return apiList;
}

export const filteredTreeResults = (filterKey, optionArray) => {
    if (!_.isUndefined(filterKey) && filterKey.length > 2 && filterKey !== '') {
        const lowerCasedFilter = _.toLower(filterKey);

        const filteredData = optionArray[0].childScopes?.length ?
            recursiveSearch('childScopes', [optionArray[0]], lowerCasedFilter) : [];
        return filteredData.length ? filteredData : {};
    }
    return optionArray;
}

export const getUserWithAccess = (user, isEdit, isResourceTypeOrg, allRoles, nonEditableRoles, currentLoginUser) => {
    const role = getRoleName(allRoles, user.roles);
    const userRoles = getRolesByIds(allRoles, user.roles, nonEditableRoles, isEdit);
    user.gridRoleName = role.name;
    user.gridRoleId =  role.id;
    user.userRoles = userRoles;
    if (isResourceTypeOrg) {
        user.deletableColumn = false;
        user.editableColumn = false;
    } else if(isEdit) {
        user.deletableColumn = user.id !== currentLoginUser;
        user.editableColumn = user.id !== currentLoginUser;
     } else {
        user.deletableColumn = false;
        user.editableColumn = user.id !== currentLoginUser
    }
    return user;
}

export const getScopeWithoutAdminAccess = (selectedScope, hasAccess = (rules) => true, scopeIdsWithoutAccess = []) => {
    if(!hasAccess(selectedScope.rules)) {
        scopeIdsWithoutAccess.push(selectedScope.id);
        for(const scope of selectedScope.childScopes) {
            if (!hasAccess(scope.rules)) {
                getScopeWithoutAdminAccess(scope, hasAccess, scopeIdsWithoutAccess)
            } else {
                break;
            }
        }
    }
    return scopeIdsWithoutAccess;
}

export const hasModifyUserAccociationAccess = (roles) => {
    return roles && roles.includes("bxp:usergroup-create") && roles.includes("bxp:usergroup-delete") && roles.includes("bxp:usergroup-modify")
}

export const mergeUsersInHierarchy = (usersInHierarchy, editedUsers = [], allRoles, currentLoginUser) => {
    const usersWithAccessMap = {};
    const usersWithOutAccessMap = {};
    let index = 0;
    while(index < usersInHierarchy.length) {
        if (!_.isUndefined(usersInHierarchy[index])) {
            for (const user of usersInHierarchy[index]) {
                if(_.isUndefined(user.roles) || user.roles.length === 0) {
                    if (index === 0 || !usersWithAccessMap[user.id] && !usersWithOutAccessMap[user.id]) {
                        usersWithOutAccessMap[user.id] = user
                    }
                } else {
                    if (usersWithAccessMap[user.id]) {
                        // update the user with acess with the user in the hierarchy.
                        usersWithAccessMap[user.id] = getUserWithAccess(user, false, false, allRoles, usersWithAccessMap[user.id].roles, currentLoginUser)
                    } else {
                        // add the user to the user with access which delete permisision if user has access to last element in the hierarchy
                        if (index === usersInHierarchy.length - 1 && user.editableColumn) {
                            usersWithAccessMap[user.id] = user;
                        } else {
                            usersWithAccessMap[user.id] = getUserWithAccess(user, index === usersInHierarchy.length - 1, false, allRoles, [], currentLoginUser)
                        }
                    }
                    // remove the users without access
                    if (usersWithOutAccessMap[user.id]) {
                        delete usersWithOutAccessMap[user.id]
                    }
                }
            }
        }
        index++;
    }
    const usersWithAccessList = [];
    // add modified users to the list if not part of users with access
    for (const editedUser of editedUsers) {
        if (!usersWithAccessMap[editedUser.id]) {
            usersWithAccessList.push(editedUser);
            if (usersWithOutAccessMap[editedUser.id]) {
                delete usersWithOutAccessMap[editedUser.id]
            }
        }
    }
    return { usersWithAccessList: [...usersWithAccessList, ...Object.values(usersWithAccessMap)],
             usersWithOutAccessList: Object.values(usersWithOutAccessMap)}
}

export const getDeleteResourceMessage = (resourceDetails) => {
    return (
        <>
            <Text level={13}>
                {`${_.capitalize(resourceDetails.resourceType)} `}
                <span className={styles['wrapping-text']}>{resourceDetails.name}</span>
                {` was successfully deleted.`}
            </Text>
        </>
    );
};

export const CATEGORY_MENU = {
    platform: "Platform",
    application: "Application",
    data_service: "Data Service"
}

export const createRoleObjectById = (roles, allRoles) => {
    return _.map(roles, (roleId) => {
        const role = allRoles.find((role) => role.id === roleId);
        role.label = role.name;
        role.value = role.id;
        return role;
    });
};

export const getEntityAndCategorySpecificRoles = (allRoles = [], entity = "", category = "") => {
    if(!entity && !category) return allRoles;
    let filteredRoles = [...allRoles];

    if(entity) {
        const keyMapping = {
            user: "isUserRole",
            serviceAccount: "isServiceAccountRole",
            agent: "isAgentRole"
        }
        filteredRoles = allRoles.filter(role => _.isUndefined(role[keyMapping[entity]]) || role[keyMapping[entity]] === "true")
    }

    if(category) {
        filteredRoles =  _.filter(filteredRoles, role => _.find(role.tags, tag => tag["internal:bxp:categoryType"] === category))
    }
   
    return filteredRoles;
}

export const getRolesFromCategory = (allRoles = [], category,  resourceType) => {

    if(_.isUndefined(category) || !_.size(allRoles) || _.isUndefined(resourceType)) {
        return [];
    }

    const filterRole =  _.filter(allRoles, role => {
        const isCategory =  role.category === category;
        let isValidResourceType = false;
        _.forEach(role.tags, tag => {
            const scopeTypeRole = tag[SCOPE_ROLE_MAP[resourceType]]
            if(!_.isUndefined(scopeTypeRole)) {
                isValidResourceType = JSON.parse(scopeTypeRole);
            }
        });
        return isCategory && isValidResourceType;
    });

    return filterRole
}