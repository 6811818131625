import _ from 'lodash';

export const getPermission = (rules, object, action = '') => {
    return rules?.find(rule => rule?.startsWith(`bxp:${object}${action ? `-${action}` : ''}`))
}
export const isAllowed = (rules, object, action = '') => getPermission(rules, object, action) !== undefined;

export const hasAccessPermission = (rules, permissionKeys) => {
    if (permissionKeys) {
        for (const permissionKey of permissionKeys) {
            if (!isAllowed(rules, permissionKey, "access")
                && !isAllowed(rules, permissionKey, "read")) {
                return false
            }
        }
        return true
    } else {
        return false
    }  
}

export const hasPermission = (rules, permissionKeys, permissionType) => {
    if (permissionKeys) {
        for (const permissionKey of permissionKeys) {
            if (!isAllowed(rules, permissionKey, permissionType)) {
                return false
            }
        }
        return true
    } else {
        return false
    }  
}

export const isRole1 = member => (member?.role === 'Role-1')

export const isOrgAdmin = (rules) => isAllowed(rules, 'organization', 'create') && isAllowed(rules, 'organization', 'modify') && isAllowed(rules, 'organization', 'delete');

export const isOrganizationAdmin = (member, roles) => {
    if (member?.roles && roles) {
        const tenancyV4Roles = _.keyBy(roles, (role) => role.id);
        return _.filter(member?.roles, (roleId) => isOrgAdmin(tenancyV4Roles[roleId]?.rules)).length > 0;
    }
    return false;
}

export const isDisabledByTags = (tags, tagsConfig) => {
    if(!_.isArray(tags)) {
        return false;
    }
    const tagsfromConfig = _.uniq(tags.filter(tag => tagsConfig.some(tagConfig => !!tag[tagConfig])).map(tagConfig => Object.keys(tagConfig)[0]));
    return tagsfromConfig.length === tagsConfig.length
}