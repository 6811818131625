import {occmClient} from "utils/clients";
import {handleApiAction} from "utils/actions";

const fetchConfig = () => occmClient.get('/occm/api/occm/config');

export default ({dispatch, getState}) => next => async action => {
    const {type, payload} = action;

    if (type === "CURRENT-CONNECTOR:INIT-CONNECTOR") {
        const state = getState();

        if (!state.tenancy.selectedAgentId) {
            return;
        }

        if (!state.currentConnector.hideUpdatesAvailable) {
            dispatch({
                type: "CURRENT-CONNECTOR:FETCH-UPDATES-AVAILABLE"
            })
        }

        setTimeout(() => {
            dispatch({
                type: "CURRENT-CONNECTOR:FETCH-ABOUT"
            })

            dispatch({
                type: "CURRENT-CONNECTOR:FETCH-SUPPORT-SERVICES"
            })

            dispatch({
                type: "CURRENT-CONNECTOR:FETCH-INSTANCE-PLACEMENT"
            })
        })
    }else if (type === "CURRENT-CONNECTOR:UPDATE-CONFIG") {
        dispatch({
            type: "NOTIFICATIONS:KILL-GROUP",
            payload: {
                group: 'CONNECTOR-SETTINGS'
            }
        });
        try {
            await occmClient.put('/occm/api/occm/config', {
                ...payload
            });
            dispatch({
                type: "CURRENT-CONNECTOR:UPDATE-CONFIG-SUCCESS",
                payload
            })
        } catch (e) {
            if (payload.autoUpgrade === true) {
                console.warn("Unable to update config")
                console.warn(e);
            }else {
                dispatch({
                    type: 'NOTIFICATIONS:ERROR',
                    payload: {
                        message: 'Failed to set configuration',
                        exception: e,
                        group: 'CONNECTOR-SETTINGS'
                    }
                });
            }
        }
    } else if (type === "CURRENT-CONNECTOR:FETCH-UPDATES-AVAILABLE") {
        try {
            const agentId = getState().tenancy.selectedAgentId;

            dispatch({
                type: "CURRENT-CONNECTOR:FETCH-UPDATES-AVAILABLE-PENDING"
            })

            const {data: config} = await fetchConfig();
            dispatch({
                type: "CURRENT-CONNECTOR:UPDATE-SYSMG",
                payload: {
                    sysMgrUserCred: config.sysMgrUserCred
                }
            })
            const updateVersionsP = occmClient.get('/occm/api/occm/system/available-update-versions');
            const {data: updateVersions} = await updateVersionsP;

            const currentAgentId = getState().tenancy.selectedAgentId;

            if (agentId === currentAgentId) {
                dispatch({
                    type: "CURRENT-CONNECTOR:FETCH-UPDATES-AVAILABLE-SUCCESS",
                    payload: {
                        updateVersions,
                        autoUpgrade: config.autoUpgrade,
                        sysMgrUserCred: config.sysMgrUserCred
                    }
                })
            }
        } catch (e) {
            console.warn("Unable to fetch update versions")
            console.warn(e);
        }
    } else if (type === "CURRENT-CONNECTOR:FETCH-ABOUT") {
        if (process.env.REACT_APP_IS_SAAS !== "true") {
            dispatch({
                type: "CURRENT-CONNECTOR:FETCH-ABOUT-SUCCESS",
                payload: {
                    data: getState().app.about
                }
            })
        } else {
            handleApiAction(action, () => {
                return occmClient.get("/occm/api/occm/system/about");
            })
        }
    } else if (type === "CURRENT-CONNECTOR:FETCH-INSTANCE-PLACEMENT") {
        if (process.env.REACT_APP_IS_SAAS !== "true") {
            dispatch({
                type: "CURRENT-CONNECTOR:FETCH-INSTANCE-PLACEMENT-SUCCESS",
                payload: {
                    data: getState().app.instancePlacement
                }
            })
        } else {
            handleApiAction(action, () => {
                return occmClient.get("/occm/api/occm/system/occm-instance-placement");
            })
        }
    } else if (type === "CURRENT-CONNECTOR:FETCH-SUPPORT-SERVICES") {
        if (process.env.REACT_APP_IS_SAAS !== "true") {
            dispatch({
                type: "CURRENT-CONNECTOR:FETCH-SUPPORT-SERVICES-SUCCESS",
                payload: {
                    data: getState().app.supportServices
                }
            })
        } else {
            handleApiAction(action, () => {
                return occmClient.get("/occm/api/occm/system/support-services");
            })
        }
    }

    return next(action);
}
